import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import LoadingIcon from '../../../util/icons/components/LoadingIcon';
import { useDispatch, useSelector } from 'react-redux';
import { setMobileMenuOpen, setSideMenuOpen } from '../../../header/actions';
import { NavLink } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { GOOD_BEEF_PAGES } from '../queries';
import './good-beef-page-menu.scss';
import ShortArrow from '../../../util/icons/components/ShortArrow';
import useDragVertically from '../../../util/hooks/useDragVertical';
import { sideMenuIsOpen } from '../../../header/reducer';

const Menu = ({ match }) => {
  let root = match.url;
  if (match.params.slug) {
    root = root.replace(`/${match.params.slug}`, '');
  }
  const { loading, error, data } = useQuery(GOOD_BEEF_PAGES);
  const [
    scrollPos,
    handleDragStart,
    handleDrag,
    handleDragEnd,
    resetMenuPosition,
    enableDragScroll
  ] = useDragVertically(window.innerWidth < 700);
  const menuIsVisible = useSelector(sideMenuIsOpen);
  useEffect(() => {
    if (menuIsVisible === false) {
      resetMenuPosition();
    }
  }, [menuIsVisible]);
  window.addEventListener('resize', () => {
    if (window.innerWidth < 700) {
      enableDragScroll(true);
    } else {
      enableDragScroll(false);
    }
  });
  const [ openMenu, setOpenMenu ] = useState(null);
  const dispatch = useDispatch();
  const handleSelection = () => {
    dispatch(setMobileMenuOpen(false));
    window.scrollTo({ top: 0 });
  };
  let content = null;
  if (loading) {
    content = (
      <li className='top-level'>
        <LoadingIcon />
      </li>
    );
  } else if (error) {
    content = (
      <li className='top-level'>
        <p>error loading content</p>
      </li>
    );
  } else {
    const multLevelMenuItems = {};
    data.goodBeefPages.forEach(page => {
      if (page.goodBeefMenuItem) {
        const item = multLevelMenuItems[page.goodBeefMenuItem.menuTitle] ||
          {
            subItems: [],
            title: page.goodBeefMenuItem.menuTitle,
            slug: page.goodBeefMenuItem.slug
          };
        item.subItems.push({
          slug: page.slug,
          title: page.menuTitle
        });
        if (match.params.slug === page.slug) {
          item.open = true;
        }
        multLevelMenuItems[page.goodBeefMenuItem.menuTitle] = item;
      }
    });
    content = data.goodBeefPages
      .filter(page => !page.goodBeefMenuItem)
      .map(page => (
        <li key={ page.slug } className='top-level' onClick={ handleSelection }>
          <NavLink to={`${root}/${page.slug}`}
            className='good-beef-page-menu-item'
            activeClassName='active'>
            <div className="text" data-cy={ `menu-option-${page.slug}` }>
              { page.menuTitle }
              <div className="line"></div>
            </div>
          </NavLink>
        </li>
      ));
    content.push(
      <li key="marbling" className='top-level' onClick={ handleSelection }>
        <NavLink to={`${root}/marbling`}
          className='good-beef-page-menu-item'
          activeClassName='active'>
          <div className="text" data-cy={ `menu-option-marbling` }>
            Marbling
            <div className="line"></div>
          </div>
        </NavLink>
      </li>
    );
    content.push(
      <li key="structure" className='top-level' onClick={ handleSelection }>
        <NavLink to={`${root}/structure`}
          className='good-beef-page-menu-item'
          activeClassName='active'>
          <div className="text" data-cy={ `menu-option-structure` }>
            Structure
            <div className="line"></div>
          </div>
        </NavLink>
      </li>
    );
    Object.values(multLevelMenuItems).forEach(item => {
      let open = item.slug === openMenu;
      const subMenuItems = item.subItems.map(subItem => {
        if (subItem.slug === match.params.slug) open = true;
        return (
          <li className="next-level"
            key={ subItem.slug }
            onClick={ handleSelection}>
            <NavLink to={`${root}/${subItem.slug}`}
              className='good-beef-page-menu-item'
              activeClassName='active'>
              <div className="text" data-cy={ `menu-option-${subItem.slug}` }>
                { subItem.title }
                <div className="line"></div>
              </div>
            </NavLink>
          </li>
        );
      });
      const className = open ? 'expandable expanded' : 'expandable';
      const height = open ? item.subItems.reduce((total, menuItem) => {
        let lettersPerLine = 26;
        if (window.innerWidth > 700 && window.innerWidth < 900) {
          lettersPerLine = 10;
        } else if (window.innerWidth <= 700) {
          if (window.innerWidth < 400) {
            lettersPerLine = 26;
          } else {
            lettersPerLine = 36;
          }
        }
        let additionalHeight = 30 +
          Math.ceil(menuItem.title.length / lettersPerLine) * 20;
        return total + additionalHeight;
      }, 0) : 0;
      const style = { maxHeight: `${height}px` };
      const handleClick = () => {
        if (item.slug === openMenu) {
          setOpenMenu(null);
        } else {
          setOpenMenu(item.slug);
        }
      };
      content.push((
        <li key={item.slug}
          className={ className }>
          <div className="expandable-text-wrapper" onClick={ handleClick }>
            <div className="text">{ item.title }</div>
          </div>
          <ul className="sub-menu" style={ style }>
            { subMenuItems }
          </ul>
        </li>
      ));
    });
  }
  const style = {
    transform: `translateY(${scrollPos}px)`
  };
  return (
    <div className="good-beef-page-menu">
      <ul data-cy="good-beef-menu"
        onTouchStart={ handleDragStart }
        onTouchMove={ handleDrag }
        onTouchEnd={ handleDragEnd }
        onTouchCancel={ handleDragEnd }
        onMouseDown={ handleDragStart }
        onMouseMove={ handleDrag }
        onMouseLeave={ handleDragEnd }
        onMouseUp={ handleDragEnd }
        style={ style }
        className='good-beef-menu-items'>
        <li className="header">
          <span className="brand">Good</span>
          <span className="standard">Beef</span>
        </li>
        <li className="back" onClick={ () => dispatch(setSideMenuOpen(false)) }>
          <ShortArrow />
          <span className='text'>Main Menu</span>
        </li>
        { content }
      </ul>
    </div>
  );
};

Menu.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.shape({
      slug: PropTypes.string
    })
  })
};

export default Menu;
