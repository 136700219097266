import { gql } from '@apollo/client';
import { getGraphCMSStage } from '../../util/api';

export const GOOD_BEEF_PAGES = gql`
{
  goodBeefPages(stage: ${getGraphCMSStage()}) {
    menuTitle
    slug
    goodBeefMenuItem {
      slug
      menuTitle
    }
  }
}
`;
