import React, { Fragment } from 'react';
import Figure from './Figure';
// import Fig1 from '../../../../img/figures/prime_steak.png';
import Fig1 from '../../../../img/figures/structure_fig1.jpg';
import Fig2 from '../../../../img/figures/structure_fig2.jpg';
import Fig3 from '../../../../img/figures/structure_fig3.jpg';
import Fig4 from '../../../../img/figures/structure_fig4.jpg';
import Fig5 from '../../../../img/figures/structure_fig5.jpg';
import Fig6 from '../../../../img/figures/structure_fig6.jpg';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

/* eslint-disable max-len */
const AnimalStructure = ({ location }) => (
  <Fragment>
    <Helmet>
      <title>Structure, a key component of Good Beef</title>
      <meta name="description" content="Structure is a crucial element of an animal’s productive
        capabilities and longevity. Find out how the GoodBeef Index scores strcutre" />
      <link rel="canonical"
        href={ `${window.location.origin}/${location.pathname}`} />
    </Helmet>
    <h1 className="main-title" data-cy="structure-title">Structure</h1>
    <h2 className="caption">a key measure of quality</h2>
    <p className="intro">Structure is a crucial element of an animal’s productive capabilities and longevity.  The GoodBeef Index factor for Structure scores an animal based on a number of detailed attributes. These attributes include: Loin Width and Depth; Leg Position and Angle; Feet Correctness; Shoulder Size and Angularity; Locomotion; Fertility; Rump Width and Depth. The attributes are accumulated into an overall average Structure score from 0 to 4, with 0 being unsatisfactory.</p>
    <div className="intro-divider"></div>
    <h4>Leg Structure</h4>
    <p className="standard-para">Leg structure is scored on both front and rear legs from the front, side and rear. Front legs are scored from the front to rank them on any deviation away from the normal line. Animals with perfect leg structure gain a score of 4. Knock kneed or bow legged are scored zero. Mild deviation away from normal scores of 3 to 1 depending on severity.</p>
    <Figure number="1" title="a cows front legs">
      <img src={Fig1} />
    </Figure>
    <p className="standard-para"><span className="highlight">Rear legs</span> are scored from the side and rear for straightness and distance between the hocks. Rear legs should have an intermediate angle of 147 degrees scoring a 4. Cattle can have legs which are too straight or bent causing uneven hoof wear and need for trimming. Too straight (160 degrees) scores 0. Too bent sickle hocked (134degrees) scores zero.  Between these ranges score proportionally to their defect between 3 and 1.</p>
    <p className="standard-para">Widths between the rear legs are viewed to see the distance between them when the animal is at ease and are scored proportionally to their correctness. </p>
    <Figure number="2" title="an animals rear legs">
      <img src={Fig2} />
    </Figure>
    <h4>Feet</h4>
    <p className="standard-para">Angle of the foot, depth at the heel, and the condition of the claw determine the score. A correct hoof has an average depth at the heel which provides even wear across the front preventing over- grown feet. Correct feet are scored 4 with lower 3 to 0 scores being given, depending on the deviation from correct. </p>
    <Figure number="3" title="a diagram of an animals feet and claws">
      <img src={Fig3} />
    </Figure>
    <h4>Shoulders</h4>
    <p className="standard-para">Size of the shoulders at the base of the neck and width behind the shoulder blades determine the score.  Large shoulders are marked down due to the relationship between larger shoulders and hard calvings.</p>
    <p className="standard-para">Angularity of the shoulders are also significant. More prominent, angular shoulders tend to have more problems at calving as figure 4 indicates.</p>
    <Figure number="4" title="a diagram of a bulls shoulders">
      <img src={Fig4} />
    </Figure>
    <h4>Locomotion</h4>
    <table>
      <tbody>
        <tr>
          <td className="heading">Score</td>
          <td className="heading">Description</td>
        </tr><tr>
          <td>4</td>
          <td>Stands and walks normally with a level back, makes long confident strides</td>
        </tr><tr>
          <td>3</td>
          <td>Stands with a flat back but arches when walks, gait slightly abnormal</td>
        </tr><tr>
          <td>2</td>
          <td>Stands and walks with an arched back and short strides with one or more legs</td>
        </tr><tr>
          <td>1</td>
          <td>Arched back when standing and walking, favouring one or more limbs</td>
        </tr><tr>
          <td>0</td>
          <td>Pronounced arching of back, reluctant to move with almost complete weight transfer from affected limb(s)</td>
        </tr>
      </tbody>
    </table>
    <h4>Loin Width and Depth</h4>
    <p className="standard-para">The loin width can be observed from  an elavated view looking from the rear towards the front of the animal. </p>
    <p className="standard-para">The loin depth or thickness can be observed just before the hips and just after the last rib from a side on view.</p>
    <p className="standard-para">A combination of the loin width and depth are used to give an overall score from 0-4, with a score of 4 being given for wider deeper loins and lower scores for thinner shallower loins.</p>
    <Figure number="5" title="Scoring cattle from score 0 on the left boxes to score 4 in the right boxes">
      <img src={Fig5} />
    </Figure>
    <h4>Rump Width and Depth</h4>
    <p className="standard-para">Rump width and depth are assessed and scored from 0-4, with 4 for very heavy muscling and 0 for lightly muscled animals. The heavy muscled animals have curved, convex rumps which are both wide and deep. Whilst lightly muscled animals have narrow, concave rumps which are generally flatter.  </p>
    <Figure number="6" title="different muscle levels in cattle">
      <img src={Fig6} />
    </Figure>
    <h4>Fertility</h4>
    <p className="standard-para">Visually assessing animals for fertility is unreliable therefore we use BCVA Bull Pre-Breeding examination certificate to quantify Scrotal size, Semen Motility and Semen Morphology.</p>
  </Fragment>
);

AnimalStructure.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};

export default AnimalStructure;
