import { useState } from 'react';

export default function useDragVertically (isEnabled) {
  const [ scrollPos, setScrollPos ] = useState(0);
  const [ enabled, setEnabled ] = useState(isEnabled);
  const [ dragging, setDragging ] = useState(false);
  const [ yStartPos, setYStartPos ] = useState(null);
  const handleDragStart = (e) => {
    if (!enabled) return;
    setDragging(true);
    let yPos = e.clientY;
    if (typeof yPos === 'undefined' && e.touches && e.touches.length) {
      yPos = e.touches[0].clientY;
    }
    setYStartPos(yPos);
  };
  const handleDrag = (e) => {
    if (!enabled) return;
    if (dragging) {
      let yPos = e.clientY;
      if (typeof yPos === 'undefined' && e.touches && e.touches.length) {
        yPos = e.touches[0].clientY;
      }
      setYStartPos(yPos);
      const diff = yPos - yStartPos;
      setScrollPos(scrollPos + diff);
    }
  };
  const handleDragEnd = (e) => {
    if (!enabled) return;
    setDragging(false);
    let yPos = e.clientY;
    if (typeof yPos === 'undefined' && e.touches && e.touches.length) {
      yPos = e.touches[0].clientY;
    }
    const diff = yPos - yStartPos;
    if (Math.abs(diff) < 30) {
      setScrollPos(scrollPos - diff);
    }
  };
  const reset = () => {
    setScrollPos(0);
  };
  const enable = (isEnabled) => {
    setEnabled(isEnabled);
  };
  return [
    scrollPos,
    handleDragStart,
    handleDrag,
    handleDragEnd,
    reset,
    enable ];
};
