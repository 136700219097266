import React, { Fragment } from 'react';
import { gql, useQuery } from '@apollo/client';
import { PropTypes } from 'prop-types';
import ShortArrow from '../../../util/icons/components/ShortArrow';
import { Link } from 'react-router-dom';
import { getGraphCMSStage } from '../../../util/api';
import SectionLoading from '../../components/SectionLoading';
import { Helmet } from 'react-helmet';
import NotFound from '../../../util/components/NotFound';

const SPECIFIC_PAGE = gql`
query GoodBeefPage($slug: String!) {
  goodBeefPage(where: { slug: $slug}, stage: ${getGraphCMSStage()}) {
    metaPageTitle
    metaPageDescription
    title
    caption
    intro
    slug
    mainBody {
      html
    }
  }
}
`;

const GoodBeefPage = ({ match, pages, location }) => {
  const { loading, error, data } = useQuery(SPECIFIC_PAGE, {
    variables: { slug: match.params.slug }
  });
  let body = null;
  let next = null;
  for (let i = 0; i < pages.length - 1; i++) {
    if (pages[i].slug === match.params.slug) {
      const path = match.url.replace(match.params.slug, pages[i + 1].slug);
      next = (
        <div onClick={ () => window.scrollTo({ top: 0 })}>
          <Link className="next-section-link" to={ path }>
            <div className="arrow">
              <ShortArrow />
            </div>
            <p className="next-section-title">next section</p>
            <p className="text" data-cy="next-page">
              { pages[i + 1].menuTitle }
            </p>
          </Link>
        </div>
      );
    }
  }
  try {
    body = { __html: data.goodBeefPage.mainBody.html };
  } catch (error) { /* */ }
  if (loading) {
    return <SectionLoading />;
  } else if (error) {
    return <p>error loading content</p>;
  } if (data.goodBeefPage === null) {
    return <NotFound />;
  } else {
    const introTitle = data.goodBeefPage.title ? (
      <h1 className="main-title" data-cy="main-title">
        { data.goodBeefPage.title }
      </h1>
    ) : null;
    const intro = data.goodBeefPage.intro ? (
      <p className="intro" data-cy="intro">{ data.goodBeefPage.intro }</p>
    ) : null;
    let title = data.goodBeefPage.metaPageTitle ||
      data.goodBeefPage.title + ' ' + data.goodBeefPage.caption;
    let meta = data.goodBeefPage.metaPageDescription ||
      title + '. Learn more from the GoodBeef Index';
    return (
      <Fragment key={ match.params.slug }>
        <Helmet>
          <title>{ title }</title>
          <meta name="description" content={ meta } />
          <link rel="canonical"
            href={ `${window.location.origin}/${location.pathname}`} />
        </Helmet>
        { introTitle }
        <h2 className="caption" data-cy="caption">
          { data.goodBeefPage.caption }
        </h2>
        { intro }
        <div className="intro-divider"></div>
        <div className="external-html-wrapper"
          data-cy="main-content-body"
          dangerouslySetInnerHTML={ body } />
        { next }
      </Fragment>
    );
  }
};

GoodBeefPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string
    }),
    url: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  pages: PropTypes.array.isRequired
};

export default GoodBeefPage;
