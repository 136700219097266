import React from 'react';
import USvsJapan from '../../../../img/figures/prime_vs_A5.png';
import QualityScalesDiagram from
  '../../../../img/figures/quality_scales_diagram.png';
import JapanGrades from '../../../../img/figures/japanese_grades.png';
import AusMeatRef from '../../../../img/figures/aus_meat_ref_trimmed.png';
import PrimeSteak from '../../../../img/figures/prime_steak.png';
import ChoiceSteak from '../../../../img/figures/choice_steak.png';
import SelectSteak from '../../../../img/figures/select_steak.png';
import MarblingImg from '../../../../img/figures/marbling.png';
import PrimeShield from '../../../../img/figures/prime_shield.png';
import ChoiceShield from '../../../../img/figures/choice_shield.png';
import SelectShield from '../../../../img/figures/select_shield.png';
import Figure from './Figure';
import './marbling.scss';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

/* eslint-disable max-len */
const Marbling = ({ match, location }) => (
  <div id="marbling-page">
    <Helmet>
      <title>Mabling, a key component of Good Beef</title>
      <meta name="description" content="Most beef produced in the world is graded
        for eating quality characteristics (tenderness, juiciness, and flavour).
        Find out how marbling contributes to quality" />
      <link rel="canonical"
        href={ `${window.location.origin}/${location.pathname}`} />
    </Helmet>
    <h1 className="main-title" data-cy="marbling-title">Marbling</h1>
    <h2 className="caption">a key determinant of beef eating quality</h2>
    <div className="intro-divider"></div>
    <p className="intro">Most beef produced in the world is graded for eating quality characteristics (tenderness, juiciness, and flavour) of the cooked product. This grading is typically done by a government agency.  High grade beef commands a higher price than lower grade beef.</p>
    <div className="intro-divider"></div>
    <h4>Marbling is the primary determinant of eating quality.</h4>
    <p className="standard-para">The quality grade of beef is based on the amount of intra-muscular fat (IMF) or “marbling” found intermingling within the meat. The greater the quantity of “marbling” the better the taste, the juicier the bite, and the higher the grade.</p>
    <p className="standard-para">Premium beef is usually evaluated based on one of three beef eating quality systems -  from either the United States, Australia, or Japan.   The EU by contrast doesn’t measure and grade based on a prediction of eating quality. So let’s break each one down.</p>
    <Figure number="1" title="Marbling example">
      <img src={MarblingImg} />
    </Figure>
    <h4>United States – Beef eating quality grading</h4>
    <p className="standard-para">The United States Department of Agriculture (or USDA), separates beef into eight different grades. The top five are sold to the consumer as cuts of beef, while the three lowest grades are typically only used for processed meats and canned meats.</p>
    <div className="us-steaks">
      <div className="prime column">
        <img className="shield-img" src={PrimeShield}/>
        <p>Prime grade is the highest quality distinction awarded to cattle. It has a high degree of marbling.  Only 2% of all the cattle raised in the United States make the grade annually.  While Prime is a great assurance of flavour and tenderness.  Other attributes must be considered, such as the feed diet, a quiet low-stress environment and the breed itself.</p>
        <img src={PrimeSteak} />
      </div>
      <div className="choice column">
        <img className="shield-img" src={ChoiceShield}/>
        <p>Choice grade is the second-highest meat quality grade. It’s still a high quality beef but has less marbling, and is likely to be less flavourful and juicy than Prime. If prepared well, it will still taste excellent.</p>
        <img src={ChoiceSteak} />
      </div>
      <div className="select column">
        <img className="shield-img" src={SelectShield}/>
        <p>Select grade beef is slightly marbled but mostly lean. It’s going to be tough to chew and will taste dry if not prepared properly.</p>
        <img src={SelectSteak} />
      </div>
    </div>
    <p className="standard-para">Restaurants generally only sell the three highest grades. High-end steakhouses only serve USDA Prime and/or Choice. Standard and Commercial grades are often sold as “ungraded”. Utility, Cutter and Canner grades are very rarely sold as cut meat. Often it is used for ground beef or in canned products ranging from corned beef to dog food! </p>
    <h4>Australia beef eating quality</h4>
    <p className="standard-para">The Australian beef grading system is known as Meat Standards Australia (or MSA) and is regulated by Meat and Livestock Australia (MLA). When calculating the MSA grade for beef, a number of attributes are measured such as meat colour, marbling, fat depth, carcass weight, maturity and pH.</p>
    <p className="standard-para">Two standards are used in combination to measure marbling.</p>
    <p className="standard-para">The AUS-MEAT Marbling system provides an indication of the amount of marbling in beef. It uses a scale of 0 (no intramuscular fat) to 9 (extreme amounts of intramuscular fat) in increments of 1.</p>
    <p className="standard-para">The MSA marbling system is graded on a scale of 100 (no intramuscular fat) to 1190 (extreme amounts of intramuscular fat) in increments of 10.</p>
    <p className="standard-para">Since the assessment criteria are different for both standards, both the AUS-MEAT and MSA scores are provided.</p>
    <Figure
      number="2"
      title="AUS-Meat and MSA Marbling Reference Standards">
      <img src={AusMeatRef} />
    </Figure>
    <p className="standard-para">The MSA marbling standard is fairly new and the older AUS-MEAT marbling standard seems to still be the norm.</p>
    <h4>Japan – beef eating quality </h4>
    <p className="standard-para">The grading of meat is managed by the JMGA (Japanese Meat Grading Association) Beef Carcass Grading Standard.</p>
    <h5>Overall Grade</h5>
    <p className="standard-para">The overall grade consists of two grades: Yield Grade (designated by a letter) and Quality Grade (designated by a number). </p>
    <p className="standard-para">Yield Grade measures the amount of usable meat on a carcass and range from A (the highest) to C (the lowest). “A” usually means the cow was a fulblood Wagyu. “B” is usually a crossbred Wagyu. “C” is a European cow such as an Angus or Holstein.</p>
    <p className="standard-para">Quality grade is calculated by evaluating four different factors: 1) meat marbling 2) meat colour and brightness 3) meat firmness and texture and 4) fat colour, lustre and quality.</p>
    <p className="standard-para">Each factor is grade from 1 to 5, with 5 being the highest score.</p>
    <p className="standard-para">In the quality grade, the lowest score from the four factors is used. In other words, even if a 5 was given to meat marbling, meat colour/brightness, and meat firmness/texture, but fat colour/lustre/quality was a 3, the quality grade of this beef would be classified as a 3.</p>
    <p className="standard-para">Beef are classified into 15 grades from the bottom end C1 to the top end A5.</p>
    {/* <img src={JapanGradeTable} /> */}
    <div className="japanese-grade-table-wrapper">
      <div className="quality-grade">Quality Grade</div>
      <div className="yield-grade">Yield Grade</div>
      <table className="grade-table">
        <tbody>
          <tr>
            <td></td>
            <td className="header">5</td>
            <td className="header">4</td>
            <td className="header">3</td>
            <td className="header">2</td>
            <td className="header">1</td>
          </tr>
          <tr>
            <td className="header">A</td>
            <td>A5</td>
            <td>A4</td>
            <td>A3</td>
            <td>A2</td>
            <td>A1</td>
          </tr>
          <tr>
            <td className="header">B</td>
            <td>B5</td>
            <td>B4</td>
            <td>B3</td>
            <td>B2</td>
            <td>B1</td>
          </tr>
          <tr>
            <td className="header">C</td>
            <td>C5</td>
            <td>C4</td>
            <td>C3</td>
            <td>C2</td>
            <td>C1</td>
          </tr>
        </tbody>
      </table>
    </div>
    <h4>Beef Marbling Standard</h4>
    <p className="standard-para">Aside from seeing “Wagyu A4” or “Wagyu A5”, you might also see “Grade 10 tenderloin” on the menu. When numbers get that high, the restaurant is referring to the Beef Marbling Standard (BMS).</p>
    <Figure
      number="3"
      title="Japanese Beef Marbling Standard (BMS)">
      <img src={JapanGrades} />
    </Figure>
    <h4>How do the US, Australian and Japanese systems compare?</h4>
    <p className="standard-para">Here’s how the 3 Beef Eating Quality systems compare.  Even if the highest grade of A5 is achieved, its marbling can still vary from No. 8 to No.12. A score of 12 is extremely rare, even in Japan. </p>
    <Figure
      number="4"
      title="Grading system comparison">
      <img src={QualityScalesDiagram} />
    </Figure>
    <p className="standard-para">A Japanese A5 grade with BMS 11 or 12 is extremely rare. Not as rare as spotting a unicorn, but close.  Probably it is only genuinely available in parts of Japan. </p>
    <p className="standard-para">On the other hand, Wagyu cattle averages are in the BMS 4-6 range, depending on genetics, nutrition, and age at time of slaughter and feed type.</p>
    <p className="standard-para">Kobe beef must have at least BMS 5 (or A4). USDA Prime has the equivalent BMS score of 4-5. </p>
    <p className="standard-para">USDA Choice has a BMS 2-4 score, which by most beef eaters is regarded as a high eating quality steak with adequate marbling to assure juiciness and succulence.  USDA 5 with a BMS of 5 or 6 would be that of a top class restaurant steak. </p>
    <p className="standard-para">Below is a comparison between a USDA Choice NY Strip and a Wagyu A5 Grade NY Strip. Notice the difference in marbling.  </p>
    <Figure
      number="5"
      title="US Choice vs Wagyu A5">
      <img src={USvsJapan} />
    </Figure>
    <p className="standard-para">When ordering at a restaurant, ask questions. Wagyu may not really be Wagyu.  “Prime” may only be “marketing  spin”. </p>
    <p className="standard-para">In an ideal world we would like to see the provenance of beef as we do with fine wines.  The GoodBeef Index is seeking to help the customer and consumer know what it is that they are buying and eating. </p>
    <h4>Using Ultra-sound and artificial intelligence technologies to measure beef eating quality</h4>
    <p className="standard-para">Applying ultra-sound measurement of intra-muscular fat, fat cover, fat depth and rib-eye muscle area provides an early prediction of the eating quality of the animal. Typically, this is done at about 12 months of age and then confirmed at 18months.  There is a strong correlation between this predictive approach and the inspections made at the abattoir and butcher, by which time it’s a bit late!  </p>
    <p className="standard-para">Top beef farms are using these technologies to select sires and dams with the best eating quality genetic traits, to make improvements even before insemination and birth.  Applying good science to help produce good beef!</p>
  </div>
);

Marbling.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};

export default Marbling;
