import React from 'react';
import { useQuery } from '@apollo/client';
import './good-beef-pages.scss';
import { PropTypes } from 'prop-types';
import Menu from './Menu';
import { Redirect, Route, Switch } from 'react-router-dom';
import GoodBeefPage from './GoodBeefPage';
import { GOOD_BEEF_PAGES } from '../queries';
import { useSelector } from 'react-redux';
import { sideMenuIsOpen } from '../../../header/reducer';
import Marbling from './Marbling';
import AnimalStructure from './AnimalStructure';
import SectionLoading from '../../components/SectionLoading';

const GoodBeef = ({ match }) => {
  const { loading, error, data } = useQuery(GOOD_BEEF_PAGES);
  const sideMenuOpen = useSelector(sideMenuIsOpen);
  let content = null;
  if (loading) {
    content = <SectionLoading />;
  } else if (error) {
    content = <p>error loading content</p>;
  } else {
    let slug = data.goodBeefPages.length ?
      data.goodBeefPages[0].slug : 'error';
    const redirectTo = `${match.url}/${slug}`;
    content = (
      <Switch>
        <Route exact path={ match.url } render={
          props => <Redirect to={ redirectTo } />
        }/>
        <Route path={ `${match.url}/marbling`} render={
          props => <Marbling { ...props } pages={ data.goodBeefPages } />
        }/>
        <Route path={ `${match.url}/structure`} render={
          props => <AnimalStructure { ...props } pages={ data.goodBeefPages } />
        }/>
        <Route path={ `${match.url}/:slug`} render={
          props => <GoodBeefPage { ...props } pages={ data.goodBeefPages } />
        }/>
      </Switch>
    );
  }
  const className = sideMenuOpen ?
    'mobile-menu-visible' : null;
  return (
    <div id="good-beef-pages" className={ className }>
      <Switch>
        <Route path={ `${match.url}/:slug`} render={
          props => <Menu { ...props } />
        } />
        <Route exact path={ `${match.url}` } component={ Menu } />
      </Switch>
      <div className="content">
        { content }
      </div>
    </div>
  );
};

GoodBeef.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string
    }),
    url: PropTypes.string
  })
};

export default GoodBeef;
