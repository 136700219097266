import React from 'react';
import './figure.scss';
import PropTypes from 'prop-types';

const Figure = ({ children, title, number }) => (
  <div className="figure">
    <div className="container">{ children }</div>
    <div className="label">
      <span className="figure">{ `Fig ${number}.` }</span>
      <span className="title">{ title }</span>
    </div>
  </div>
);

Figure.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  title: PropTypes.string,
  number: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default Figure;
